// About Info
.about-info {
  position: relative;
  align-items: flex-start;
  background: $green;
  height: 130vh;
  border-radius: 100px;
  margin-bottom: $spacer-lg;
}

// Icons
.about-info__star {
  $s: 15rem;
  width: $s;
  height: $s;
  position: absolute;
  bottom: 2.5vh;
  left: 3.5vw;
}

.about-desc__puzzle {
  $s: 12.5rem;
  width: $s;
  height: $s;
  position: absolute;
  top: 2.5vh;
  left: 2.5vw;
}

// Content
.about-desc {
  position: relative;
  width: 47.5%;
  min-height: 75%;
  background: $light-green;
  padding-top: 275px;
  padding-right: $spacer-md;
  padding-left: $padding * 0.75;
  border-radius: 100px 0px 200px 0px;
}

.about-desc__title {
  color: $dark-blue;
  font-family: $mplus-font;
  font-weight: 800;
  font-size: $lg-fs;
  margin-bottom: $spacer;
}

.about-desc__text {
  width: 50ch;
  color: $dark-blue;
  font-family: $mplus-font;
  line-height: $spacer * 1.25;
  font-weight: 500;
  margin-bottom: $spacer-md * 1.5;
}

.about-desc__link {
  width: fit-content;
  background: $dark-gold;
  color: $dark-blue;
  font-family: $mplus-font;
  font-weight: 700;
  text-decoration: none;
  padding: $spacer-small * 0.75 $spacer-md;
  border-radius: 35px;
  font-size: $sm-fs * 1.15;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  transition: all 200ms ease-out;

  &:hover {
    box-shadow: 4px 8px 4px rgba(0, 0, 0, 0.25);
  }

  .right-arrow {
    transform: scale(1.25);
    margin-left: $spacer-small * 0.75;

    path {
      fill: $dark-blue;
    }
  }
}

.about-cards {
  position: relative;
  display: grid;
  grid-template-columns: 25% 25%;
  column-gap: $spacer-lg * 2.25;
  row-gap: $spacer-lg;
  grid-auto-rows: 40% 40%;
  width: 50%;
  min-height: 100%;
  padding: $spacer-lg * 1.5 $spacer-md * 1.5;
}

.about-cards__card {
  position: relative;
  width: 20vw;
  height: fit-content;
  background: white;
  border-radius: 25px;
  padding: $spacer;
  padding-top: $spacer-md * 1.65;
  padding-bottom: $spacer * 1.25;
}

.about-cards__title {
  color: $dark-blue;
  font-size: $sm-fs * 1.5;
  font-family: $mplus-font;
  font-weight: 900;
  line-height: $spacer-small * 1.5;
  margin-bottom: $spacer-small * 0.75;
}

.about-cards__info {
  color: black;
  font-size: $sm-fs * 1.15;
  font-family: $mplus-font;
  font-weight: 500;
  line-height: $spacer-small * 1.5;
}

.about-cards__icon {
  $s: 5rem;
  position: absolute;
  top: $s * -0.25;
  left: $s * -0.25;
  width: $s;
  height: $s;
  background: white;
  border-radius: 50%;

  img {
    width: 55%;
    height: 55%;
  }
}

#female-teacher {
  background: $dark-gold;
  box-shadow: 3px 4px 7.5px 0px rgba(0, 0, 0, 0.25);

  .about-cards__icon {
    background: $light-gold;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  }
}

#health,
#shapes {
  transform: translateY($spacer-lg);
}

#language img {
  transform: rotate(-21deg);
}

// Animations
.about-cards-animation {
  $init-delay: 200ms;
  $ani-dur: 550ms;

  #female-teacher {
    @include fade-in($ani-dur, $init-delay);
  }

  #health {
    @include fade-in($ani-dur, $ani-dur + $init-delay);
  }

  #language {
    @include fade-in($ani-dur, ($ani-dur + $init-delay) * 2);
  }

  #shapes {
    @include fade-in($ani-dur, ($ani-dur + $init-delay) * 3);
  }
}
