// Navbar Styles
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: $spacer-small $padding;
  box-shadow: 0 1px 5px 0 rgba(#d2d2d2, 0.5);
  background: white;
  z-index: 1000;
}

// Availability
.availability {
  position: absolute;
  bottom: -3.5vh;
  left: 0;
  right: 0;
  background: rgba(#f8f5f5, 0.75);
  text-align: center;
  padding: $spacer-small * 0.5 0;
  font-size: $sm-fs;
  color: $dark-blue;
}

// Logos
.logo {
  margin-right: $spacer;
}

.logo__img {
  $s: 3rem;
  width: $s;
  height: $s;
  margin-right: $spacer-small;
}

.logo__title {
  font-family: $bubble-font;
  color: $dark-blue;
  font-size: $md-fs;
  text-decoration: none;
}

// Links
.links__link {
  color: $dark-blue;
  font-family: $mplus-font;
  text-decoration: none;
  margin-left: $spacer;
  font-weight: 600;
  font-size: $sm-fs;

  &:hover {
    color: $blue;
  }
}

// Button Links
.contact-link,
.signup-link {
  outline: none;
  border: none;
  border-radius: 25px;
  font-family: $mplus-font;
  padding: $spacer-small * 0.5 $spacer * 1.5;
  text-decoration: none;
  font-weight: 600;
  font-size: $sm-fs;
  transition: all 200ms ease-out;
}

.contact-link {
  color: white;
  background: $blue;
  border: 2px solid $blue;

  &:hover {
    transform: scale(1.025);
    box-shadow: 2px 4px 10px 0 $light-shadow;
  }
}

.signup-link {
  color: $dark-blue;
  border: 2px solid $dark-blue;
  margin-left: $spacer;

  &:hover {
    background: $dark-blue;
    color: white;
  }
}

// Mobile Navbar
.mobile-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: $m-padding * 0.5 $m-padding;
  box-shadow: 0 1px 5px 0 rgba(#d2d2d2, 0.5);
  background: white;
  z-index: 1000;

  .logo__img {
    $s: 3rem;
    width: $s;
    height: $s;
  }

  .logo__title {
    font-size: $sm-fs * 1.5;
  }
}

.mobile-navbar__btn {
  $s: 3rem;
  width: $s;
  height: $s;
  outline: none;
  border: none;
  background: transparent;

  svg {
    width: 100%;
    height: 100%;

    path {
      fill: $dark-blue;
    }
  }
}

.mobile-nav-shadow {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  background: rgba(black, 0.5);
  z-index: 10000;
}

.mobile-nav-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 80vw;
  height: 100vh;
  overflow-y: hidden;
  background: white;
  box-shadow: 0 4px 10px 5px rgba(black, 0.15);
  padding: $m-padding * 0.5 $m-padding * 0.5;

  .logo__img {
    $s: 2.5rem;
    width: $s;
    height: $s;
    margin-right: $spacer-small * 0.75;
  }

  .logo__title {
    font-size: $sm-fs * 1.25;
  }
}

.mobile-nav-menu__close {
  $s: 2.5rem;
  width: $s;
  height: $s;
  outline: none;
  border: none;
  background: transparent;

  svg {
    width: 100%;
    height: 100%;

    path {
      fill: $dark-blue;
    }
  }
}

.mobile-nav-menu__header {
  margin-bottom: $spacer-md;
}

.mobile-nav-menu__links {
  display: flex;
  flex-direction: column;
}

.mobile-nav-menu__link {
  text-decoration: none;
  margin-bottom: $spacer;
  background: $dark-blue;
  color: white;
  padding: $spacer-small $spacer;
  border-radius: 25px;
  font-family: $mplus-font;
  font-weight: 600;
}
