// Image Carousel Styles
$carousel-duration: 27s;
$carousel-items: 7;
$slide-change-timing: 3;
$carousel-delay-fraction: calc($carousel-duration / $carousel-items);
$carousel-steps-fraction: calc(100 / $carousel-items);
$carousel-easing: cubic-bezier(0.37, 0, 0.63, 1);

@keyframes carousel-animate {
  0% {
    visibility: hidden;
    opacity: 0;
    transform: translateX(200%) scale(0.7);
  }
  #{$slide-change-timing}%,
  #{$carousel-steps-fraction}% {
    visibility: visible;
    opacity: 0.8;
    transform: translateX(100%) scale(0.9);
  }
  #{$carousel-steps-fraction + $slide-change-timing}%,
  #{$carousel-steps-fraction * 2}% {
    visibility: visible;
    opacity: 1;
    transform: translateX(0) scale(1);
  }
  #{($carousel-steps-fraction * 2) + $slide-change-timing}%,
  #{$carousel-steps-fraction * 3}% {
    visibility: visible;
    opacity: 0.8;
    transform: translateX(-100%) scale(0.9);
  }
  #{($carousel-steps-fraction * 3) + $slide-change-timing}% {
    visibility: visible;
    opacity: 0;
    transform: translateX(-200%) scale(0.9);
  }
  100% {
    visibility: hidden;
    opacity: 0;
    transform: translateX(-200%) scale(0.7);
  }
}

:root {
  --light: 0;
  --light: 0;
  --max-width-post: 420px;

  --primary: hsl(
    calc(64 + 220 * var(--light)),
    calc(77% - 7% * var(--light)),
    calc(79% - 56% * var(--light))
  );

  --bg: hsl(
    calc(284 - 220 * var(--light)),
    calc(70% + 7% * var(--light)),
    calc(23% + 56% * var(--light))
  );

  --text-primary: hsl(
    calc(60 * var(--light)),
    calc(19% * var(--light)),
    calc(97% - 89% * var(--light))
  );

  --font-size-sm: clamp(0.7rem, 0.91vw + 0.47rem, 1.2rem);
  --font-size-base: clamp(0.88rem, 1.14vw + 0.59rem, 1.5rem);
  --font-size-md: clamp(1.09rem, 1.42vw + 0.74rem, 1.88rem);
  --font-size-lg: clamp(1.37rem, 1.78vw + 0.92rem, 2.34rem);
  --font-size-xl: clamp(1.71rem, 2.22vw + 1.15rem, 2.93rem);
  --font-size-xxl: clamp(2.14rem, 2.77vw + 1.44rem, 3.66rem);
  --font-size-xxxl: clamp(2.67rem, 3.47vw + 1.8rem, 4.58rem);
}

.container {
  --container-padding-horizontal: 32px;
  position: relative;
  padding-inline: var(--container-padding-horizontal);
  display: grid;
  place-items: center;
  height: 100%;
  width: 100%;
}

.carousel {
  pointer-events: none;
  position: absolute;
  padding-block-start: 67px;
  padding-block-end: max(24px, calc(29px + var(--font-size-md)));
  perspective: 100px;
  width: 100%;
  @media (max-width: 568px) {
    padding-block-end: 52px;
  }
  &__wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    height: 61.2vh;
  }
  .item {
    position: absolute;
    height: 50vh;
    box-shadow: 0 0 7.5px 2.35px $shadow;
    border-radius: 15px;
    margin-inline: var(--container-padding-horizontal);
    opacity: 0;
    will-change: transform, opacity;
    animation: carousel-animate $carousel-duration $carousel-easing infinite;
    @media (max-width: 568px) {
      margin-inline: calc(var(--container-padding-horizontal) + 1px);
    }
    @for $i from 1 through ($carousel-items - 1) {
      &:nth-child(#{$i}) {
        animation-delay: calc(#{$carousel-delay-fraction} * #{$i - 2});
      }
    }
    &:last-child {
      animation-delay: calc(-#{$carousel-delay-fraction} * 2);
    }
  }
  img {
    width: 50vw;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
    &:hover {
      cursor: pointer;
    }
  }
}
