// Top Background Style
$top-br: 100px;
.top-bg {
  position: relative;
  width: 100vw;
  height: 50vh;
  border-radius: 0px 0px $top-br $top-br;
  margin-bottom: $spacer-lg * 1.5;
  margin-top: 10vh;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($yellow, 0.9);
    border-radius: 0px 0px $top-br $top-br;
  }
}

.top-bg__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0px 0px $top-br $top-br;
}

.top-bg__title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 100;
  color: $dark-blue;
  font-size: $lg-fs * 2.25;
  font-family: $bubble-font;
}

// Media Queries
@media (max-width: 435px) {
  /* smartphones, iPhone, portrait 480x320 phones */

  $top-br: 25px;
  .top-bg {
    width: 100vw;
    height: 35vh;
    border-radius: 0px 0px $top-br $top-br;
    margin-bottom: $spacer-md;

    &::after {
      border-radius: 0px 0px $top-br $top-br;
    }
  }

  .top-bg__img {
    border-radius: 0px 0px $top-br $top-br;
  }

  .top-bg__title {
    width: 100%;
    text-align: center;
    font-size: $md-fs * 1.75;
    line-height: $spacer-md;
  }
}

// Media Queries
@media (max-width: 435px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .top-bg {
    margin-top: 7.5vh;
  }
}
