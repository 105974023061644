// Setup
@import "setup/reset";
@import "setup/vars";
@import "utils/alignments";
@import "utils/animations";

// Standalone Components
@import "components/standalone/navbar";
@import "components/standalone/footer";
@import "components/standalone/carousel";
@import "components/standalone/ripple-btn";
@import "components/standalone/top-background";
@import "components/standalone/loading";
@import "components/standalone/play-icon";
@import "components/standalone/scroll-btns";

// Page Components
@import "components/pages/home/about-info";
@import "components/pages/home/home";
@import "components/pages/about/about";
@import "components/pages/not-found/not-found";
@import "components/pages/contact/contact";
@import "components/pages/program/program";

// Styles
body,
.App {
  position: relative;
  overflow-x: hidden;
}
