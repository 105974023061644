// Media Queries
@media (max-width: 435px) {
  /* smartphones, iPhone, portrait 480x320 phones */

  // About Daycare Provider
  .about-dc-provider {
    padding: 0 $m-padding;
    flex-direction: column;
    align-items: center;
    margin-bottom: $spacer-lg;
  }

  .about-dc-provider__img {
    margin-right: 0;
    border-radius: 15px !important;
    margin-bottom: $spacer-md;
  }

  .about-dc-provider__title,
  .about-daycare__title {
    font-size: $lg-fs;
    text-align: center;
    margin-bottom: $spacer-small * 1.25;
  }

  .about-dc-provider__text,
  .about-daycare__text {
    text-align: center;
    font-size: $sm-fs * 1.15;
    width: 90vw;
  }

  .about-dc-provider__text {
    width: 90vw;
    font-size: $sm-fs * 1.15;
    line-height: $spacer * 1.5;
  }

  // About Daycare
  .about-daycare {
    width: 100vw;
    background: $gold;
    padding: $spacer-md 0;
    padding-left: $m-padding;
    padding-right: $m-padding;
    border-radius: 0;
    margin-bottom: $spacer-lg;
    box-shadow: 0px 0px 15px 2.5px rgba(0, 0, 0, 0.15);
  }

  .about-daycare__img {
    display: none;
  }

  .about-daycare__text {
    width: 100%;
    margin-bottom: $spacer;
  }

  // About Video
  .about-video__title {
    font-size: $lg-fs * 1.15;
    margin-bottom: $spacer * 1.35;
  }

  .about-video__box {
    position: relative;
    width: 100vw;
    height: 35vh;
    margin-bottom: $spacer-lg * 2;

    video,
    .react-player__preview {
      transform: scale(0.95);
      position: absolute;
      top: 0;
      width: 100vw !important;
      height: 35vh !important;
    }
  }
}
