// Scroll Buttons
.nav-btn-box {
  $s: 3rem;
  min-width: $s;
  height: $s;
  position: fixed;
  bottom: 1vw;
  right: 1vw;
  z-index: 1000;
  background: #a475ff;
  border-radius: 15px;
  box-shadow: 0 0 15px 5px rgba(#a475ff, 0.75);
  transition: all 200ms ease-out;
  animation: shorten forwards 500ms ease-out;

  &:hover {
    transform: scale(1.1);
  }
}

.nav-btn-box__toggle {
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  background: transparent;
  padding: 0;

  .short-left-arrow path {
    fill: white;
    transition: all 200ms ease-out;
    @include fade-in(250ms, 600ms);
  }

  .close {
    width: 1.25rem;
    transition: all 200ms ease-out;

    path {
      fill: white;
    }
  }

  &:hover {
    cursor: pointer;

    .short-left-arrow path {
      fill: $yellow;
    }
  }
}

.nav-btn-box__active {
  flex-direction: column;
  animation: lengthen forwards 500ms ease-out;

  .nav-btn-box__toggle {
    width: 3rem;
    height: 3rem;
    margin-bottom: $spacer;
    @include fade-in(250ms, 600ms);

    &:hover {
      .close {
        path {
          fill: $yellow;
        }
      }
    }
  }

  &:hover {
    transform: scale(1);
  }
}

.nav-btns {
  width: 2rem;
  height: 2rem;
  outline: none;
  border: none;
  background: #e975ff;
  border-radius: 5px;
  margin-bottom: $spacer-small;
  transition: all 200ms ease-out;
  @include fade-in(250ms, 600ms);

  .short-up-arrow,
  .short-down-arrow {
    path {
      fill: white;
    }
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
}

// Media Queries
@media (max-width: 435px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .nav-btn-box {
    bottom: 2vw;
    right: 2vw;
    box-shadow: 0 0 15px 2.5px rgba(#a475ff, 0.75);

    &:hover {
      transform: scale(1);
    }
  }
}
