// Footer Styles
.footer {
  background: rgba(242, 242, 242, 0.9);
  padding: $spacer-md $padding;
  height: 40vh;
  align-items: flex-start;
}

.footer-left {
  height: 100%;
  flex-direction: column;
  align-items: flex-start;

  .logo__img {
    $s: 7.5rem;
    width: $s;
    height: $s;
  }

  .logo__title {
    font-size: $lg-fs;
    margin-left: $spacer;
  }

  &__link {
    text-decoration: none;
    color: $dark-blue;
    font-family: $mplus-font;
    font-size: $sm-fs * 1.15;
  }

  &__link + &__link:before {
    content: " | ";
    padding: 0 $spacer;
    color: rgba(0, 0, 89, 0.15);
  }
}

.footer-right {
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
}

.footer-contact__title {
  color: $dark-blue;
  font-size: $sm-fs * 1.35;
  font-weight: 900;
  font-family: $mplus-font;
  margin-bottom: $spacer;
}

.footer-contact__info {
  margin-bottom: $spacer-small;
  color: $dark-blue;
  font-family: $mplus-font;
}

.footer-right__copyright {
  color: $dark-blue;
  font-family: $mplus-font;
  font-size: $sm-fs;
}

// Media Queries
// Media Queries
@media (max-width: 435px) {
  /* smartphones, iPhone, portrait 480x320 phones */

  .footer {
    flex-direction: column;
    align-items: center;
    padding: $spacer-md $m-padding;
    height: fit-content;

    .logo {
      flex-direction: column;
      align-items: center;
      margin-right: 0;
    }
  }

  .footer-left {
    align-items: center;

    .logo__img {
      margin-bottom: $spacer;
    }

    .logo__title {
      font-size: $lg-fs;
      margin-left: 0;
      margin-bottom: $spacer * 1.5;
    }

    &__links {
      flex-direction: column;
      margin-bottom: $spacer-md;
    }

    &__link {
      font-size: $sm-fs * 1.35;
      margin-bottom: $spacer;
    }

    &__link + &__link:before {
      display: none;
    }
  }

  .footer-right {
    align-items: center;
  }

  .footer-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: $spacer-md;
  }

  .footer-contact__title {
    font-size: $sm-fs * 1.35;
    margin-bottom: $spacer;
  }

  .footer-right__copyright {
    font-size: $sm-fs * 1.05;
  }
}
