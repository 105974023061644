// Video Play Icon
.play-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.outer_circle {
  stroke-width: 3;
  stroke-dasharray: 410;
  stroke-dashoffset: 0;
  stroke-linecap: square;
  transition: all 0.4s ease-out;
}

.play-box:hover .outer_circle {
  stroke-dashoffset: 410;
  transition: stroke 0.7s 0.4s ease-out, stroke-dashoffset 0.4s ease-out;
}

.play-box:hover .inner-circle {
  fill: $teal;
  transition: fill 0.4s 0.3s ease-out;
}

.play-box:hover .play {
  fill: white;
  transition: fill 0.4s 0.3s ease-out;
}
