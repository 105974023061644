.between-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.around-row {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.center-row {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.row {
  display: flex;
  align-items: center;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
}
