// Contact Page Styles
.contact-page {
  min-height: 100vh;

  .top-bg {
    margin-bottom: $spacer-md * 1.75;
  }
}

.contact-page__content {
  padding: 0 $padding * 1.25;
}

.contact-page__title {
  text-align: center;
  color: $dark-blue;
  font-size: $lg-fs * 1.5;
  font-family: $mplus-font;
  font-weight: 900;
  margin-bottom: $spacer-md * 1.5;
}

.contact-form {
  width: 100%;
  min-height: 50vh;
  margin-bottom: $spacer-lg;

  .row {
    align-items: flex-start;
    width: 100%;
  }
}

.contact-form__left {
  margin-right: $spacer-md;
  width: 40%;
}

.contact-form__right {
  margin-right: $spacer-md;
  width: 60%;
}

.contact-form__input,
.contact-form__textarea {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: $mplus-font;
  margin-bottom: $spacer;
}

.contact-form__textarea {
  height: 50vh;
}

.contact-form__label {
  font-weight: 700;
  font-size: $sm-fs * 1.25;
  margin-bottom: $spacer-small * 0.75;
}

.contact-form__input input,
.contact-form__textarea textarea {
  width: 100%;
  font-size: $sm-fs * 1.25;
  outline: none;
  padding: $spacer-small * 0.5 $spacer-small * 0.75;
  border-radius: 5px;
  border: 1px solid #d0d0d0;
  background: #fff;
  transition: all 200ms ease-out;

  &:focus {
    border-color: $teal;
  }
}

.contact-form__textarea textarea {
  font-size: $sm-fs * 1.15;
  height: 100%;
  padding: $spacer-small * 1.25;
  resize: none;
  line-height: $spacer * 1.25;
}

.contact-form__submit {
  outline: none;
  border: none;
  background: $teal;
  color: white;
  font-size: $sm-fs * 1.15;
  font-family: $mplus-font;
  font-weight: 700;
  padding: $spacer-small * 0.5 $spacer-md * 1.25;
  border-radius: 25px;
  margin-top: $spacer;
  transition: all 200ms ease-out;

  &:hover {
    cursor: pointer;
    opacity: 0.75;
  }
}

.contact-form__ch-count {
  color: grey;
  font-family: $mplus-font;
  font-weight: 500;
  text-align: right;
}

.contact-form__error input,
.contact-form__error textarea {
  border-color: $red;
}

.contact-form__error-msg {
  display: flex;
  align-items: center;
  background: rgba($red, 0.25);
  color: $red;
  font-weight: 500;
  padding: $spacer-small * 0.5 $spacer-small * 0.75;
  border-radius: 0 0 7.5px 7.5px;

  svg {
    margin-right: $spacer-small * 0.5;
  }
}

.contact-form__error-textarea {
  border-radius: 7.5px;
}

// Error Message & Success Message
.contact-page__error-container,
.contact-page__success-container {
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(black, 0.5);
  z-index: 2000;
}

.contact-page__error-box,
.contact-page__success-box {
  width: 25vw;
  background: white;
  border-radius: 25px;
}

.contact-page__error-content,
.contact-page__success-content {
  padding: $spacer $spacer * 1.25;
  font-family: $mplus-font;
}

.contact-page__error-header,
.contact-page__success-header {
  background: #ff4e4e;
  padding: $spacer 0;
  border-radius: 25px 25px 0 0;

  svg {
    transform: scale(1.5);
  }
}

.contact-page__error-title,
.contact-page__success-title {
  font-size: $md-fs * 1.25;
  color: $dark-blue;
  font-weight: 800;
  margin-bottom: $spacer-small;
}

.contact-page__error-msg,
.contact-page__success-msg {
  color: $dark-blue;
  font-size: $sm-fs;
  font-weight: 500;
  text-align: center;
  margin-bottom: $spacer * 1.5;
  line-height: $spacer-small * 1.25;
}

.contact-page__error-close,
.contact-page__success-close {
  outline: none;
  border: none;
  background: #ff4e4e;
  color: white;
  font-size: $sm-fs;
  font-family: $mplus-font;
  font-weight: 700;
  padding: $spacer-small * 0.25 $spacer * 1.25;
  border-radius: 25px;
  transition: all 200ms ease-out;

  &:hover {
    cursor: pointer;
    opacity: 0.75;
  }
}

.contact-page__success-header,
.contact-page__success-close {
  background: #5bd75b;
}

// Contact Page - Schedule a Visit
.contact-sv {
  width: 100%;
  align-items: flex-start;
  border-radius: 5px;
  background: white;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.05);
  margin-bottom: $spacer-lg * 2;
  padding: $spacer * 1.25;
  font-family: $mplus-font;
}

.contact-sv__title {
  color: $dark-blue;
  font-size: $md-fs * 1.25;
  font-weight: 900;
  margin-bottom: $spacer-small;
}

.contact-sv__text {
  color: $dark-blue;
  font-size: $sm-fs * 1.25;
  max-width: 45ch;
  line-height: $spacer * 1.25;
  margin-right: $spacer-md;
}

.contact-details {
  border-left: 2px solid #eee;
  padding-left: $spacer-md;
}

.contact-details__title {
  color: $dark-blue;
  font-size: $md-fs;
  margin-bottom: $spacer;
}

.contact-details__info {
  border: 2px solid $teal;
  border-radius: 0 25px 25px 0;
  padding: $spacer-small * 0.5 $spacer-small;
  padding-right: $spacer-md;
  margin-bottom: $spacer-small;
  color: $teal;
  font-weight: 700;

  svg {
    margin-right: $spacer-small * 0.75;
  }
}

// Media Queries
@import "./mq";
