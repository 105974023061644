// Program Page Styles

.program-page {
  min-height: 100vh;

  .top-bg {
    margin-bottom: $spacer-lg;
  }

  main {
    padding: 0 $padding;
    font-family: $mplus-font;
  }
}

// Program Description
.program-desc {
  align-items: flex-start;
}

.program-desc__title,
.sh-content__title,
.ms-content__title {
  color: $dark-blue;
  font-size: $lg-fs;
  font-weight: 900;
  margin-bottom: $spacer;
}

.program-desc__text,
.ms-content__text {
  max-width: 70ch;
  font-size: $sm-fs * 1.25;
  font-weight: 500;
  line-height: $spacer * 1.35;
}

.program-desc__img {
  $s: 25vw;
  width: $s;
  height: $s;
  border-radius: 25px;
  margin-left: $spacer-lg;
  box-shadow: 0 0 10px 2.5px rgba(black, 0.25);
}

// Schedule & Hours
.sh-content {
  margin-bottom: $spacer-lg;
}

.sh-content__sub-heading {
  font-size: $sm-fs * 1.25;
  font-weight: 500;
  margin-bottom: $spacer-small;
}

.sh-content__sub-heading-2 {
  font-size: $sm-fs * 1.25;
  font-weight: 500;
  color: #808080;
  margin-bottom: $spacer-md;
}

.sh-cards__card {
  width: 25vw;
  height: 70vh;
  background: white;
  border: 1px solid #eee;
  padding: $spacer-small * 1.5;
  flex-direction: column;
  align-items: flex-start;
  margin-right: $spacer-md;
}

.sh-cards__card-text {
  margin-bottom: $spacer;
}

.sh-cards__card-time {
  font-size: $sm-fs * 1.15;
  font-weight: 500;
  margin-bottom: $spacer-small * 0.5;
}

.sh-cards__card-activity {
  font-size: $sm-fs * 1.25;
  font-weight: 800;
  line-height: $spacer;
}

.sh-cards__msg {
  color: #808080;
  text-align: center;
  font-size: $sm-fs * 1.15;
  font-weight: 500;
  line-height: $spacer;
}

.sh-cards__card-heading {
  font-size: $sm-fs * 1.5;
  font-weight: 800;
  margin-bottom: $spacer-small * 0.75;
}

.sh-cards__card-sub-heading {
  color: #808080;
  font-size: $sm-fs * 1.15;
  font-weight: 400;
  margin-bottom: $spacer * 1.15;
}

.sh-cards__card-holiday {
  font-size: $sm-fs * 1.15;
  font-weight: 800;
  margin-bottom: $spacer-small * 1.225;
}

// Meals & Supplies
.ms-content {
  margin-bottom: $spacer-lg * 2;
}

.ms-content__text {
  max-width: 50ch;
  margin-bottom: $spacer-small * 1.75;
}

.ms-content__bold {
  font-size: $sm-fs * 1.25;
  font-weight: 800;
  margin-bottom: $spacer-small * 0.25;
}

@import "./mq";
