/* Variables */

/* Colors */
$green: #a3ff8a;
$light-green: #8affac;
$blue: #758bff;
$orange: #ffb42c;
$red: #ff758b;
$gold: #fff3a9;
$light-gold: #f7f4d5;
$dark-gold: #eee8aa;
$dark-blue: #000040;
$yellow: #fff3a9;
$teal: #00cdcd;

// Shadows
$shadow: rgba(0, 0, 0, 0.25);
$light-shadow: rgba(0, 0, 0, 0.15);

/* Font */
$sm-fs: 0.9rem;
$md-fs: 1.5rem;
$lg-fs: 2rem;
$bubble-font: "Bubblegum Sans", sans-serif;
$mplus-font: "M PLUS Rounded 1c", sans-serif;

/* Spacers */
$spacer-small: 1rem;
$spacer: $spacer-small * 1.5;
$spacer-md: $spacer * 2;
$spacer-lg: $spacer-md * 2;
$padding: 12.5%;
$m-padding: 6.25%;
