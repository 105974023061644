// Media Queries
@media (max-width: 435px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .program-page {
    .top-bg {
      margin-bottom: $spacer-md;
    }

    main {
      padding: 0 $m-padding;
    }
  }

  // Program Description
  .program-desc {
    align-items: center;
    flex-direction: column-reverse;
    margin-bottom: $spacer-lg;
  }

  .program-desc__title,
  .sh-content__title,
  .ms-content__title {
    text-align: center;
  }

  .program-desc__text,
  .ms-content__text {
    text-align: center;
  }

  .program-desc__img {
    $s: 20rem;
    width: $s;
    height: $s;
    margin-left: 0;
    margin-bottom: $spacer-md;
  }

  // Schedule & Hours
  .sh-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: $spacer-md;
  }

  .sh-content__sub-heading {
    text-align: center;
    line-height: $spacer;
  }

  .sh-content__sub-heading-2 {
    max-width: 70vw;
    text-align: center;
    margin-bottom: $spacer-md;
  }

  .sh-cards {
    flex-direction: column;
  }

  .sh-cards__card {
    width: 100%;
    height: fit-content;
    margin-right: 0;
    margin-bottom: $spacer-md;
  }

  .sh-cards__msg {
    margin-top: $spacer * 1.5;
  }

  // Meals & Supplies
  .ms-content {
    margin-bottom: $spacer-lg * 2;
  }

  .ms-content__text {
    text-align: center;
  }

  .ms-content__bold {
    text-align: center;
  }
}
