// Animation Styles

@mixin slide-in($dur, $delay) {
  opacity: 0;
  transform: translateX(50vw);
  animation: slide-in forwards $dur ease-out $delay;
}

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateX(50vw);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@mixin fade-in($dur, $delay) {
  animation: fade-in forwards $dur ease-in $delay;
  opacity: 0;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@mixin spin($dur, $delay) {
  animation: spin infinite alternate $dur ease-in $delay;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

// For Nav Buttons Box on Home Page
@keyframes lengthen {
  from {
    height: 3rem;
  }
  to {
    height: 10rem;
  }
}

@keyframes shorten {
  from {
    height: 10rem;
  }
  to {
    height: 3rem;
  }
}
