// Home Styles
.home-page {
  position: relative;
  padding-top: 10vh;
}

// Rectangles
.yellow-rect {
  position: absolute;
  top: 12.5vh;
  left: -29.5%;
  z-index: -1;

  // Styles from FIGMA
  width: 500px;
  height: 500px;
  transform: rotate(70deg);
  flex-shrink: 0;
  border-radius: 95px;
  background: rgba($yellow, 0.8);
  box-shadow: 0px 0px 7.5px 5px rgba(0, 0, 0, 0.05);
}

.green-rect {
  position: absolute;
  top: -30vh;
  right: -15vw;
  z-index: -1;

  // Styles from FIGMA
  width: 847.917px;
  height: 800px;
  transform: rotate(39.639deg);
  flex-shrink: 0;
  border-radius: 250px 250px 150px 250px;
  background: $green;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.15);
}

// Hero Content
.hero {
  margin-top: 15vh;
  z-index: 100;
  padding: 0 $padding;
}

.hero-content {
  width: 30vw;
}

.hero-content__title {
  color: $dark-blue;
  font-family: $bubble-font;
  font-size: $lg-fs * 2;
  margin-bottom: $spacer;
}

.hero-content__text {
  color: $dark-blue;
  font-family: $mplus-font;
  line-height: $spacer * 1.25;
  margin-bottom: $spacer-md;
}

.hero__img {
  width: 550px;
  height: 500px;
  border-radius: 50px 5px;
  box-shadow: 0px 0px 10px 5px $shadow;
  object-fit: cover;
  @include slide-in(1000ms, 0ms);
}

// Inside Images
.inside-images {
  margin-top: $spacer-lg * 2.5;
}

.bg-svg {
  width: 100vw;
  transform: scale(1.5);
}

.inside-images-content {
  background: $blue;
  padding: $spacer-lg 0;
  z-index: 10;
  min-height: 90vh;
  border-radius: 0px 0px 50px 50px;
  margin-bottom: $spacer-lg * 1.5;
}

.inside-images-content__title {
  color: white;
  font-weight: 900;
  font-family: $mplus-font;
  text-align: center;
  font-size: $lg-fs * 1.5;
}

// Schedule Visit
.schedule-visit {
  margin-top: $spacer-lg * 1.5;
  margin-bottom: $spacer-lg * 1.5;

  .red-wave {
    width: 100vw;
    transform: scale(1.1);
  }

  .reverse {
    transform: scale(1.1) rotate(180deg);
  }
}

.sv-content {
  background: $red;
  padding: 0 $padding;
  z-index: 100;
  height: 70vh;

  .between-row {
    align-items: flex-start;
  }
}

.sv-content__title {
  color: white;
  font-weight: 900;
  font-family: $mplus-font;
  text-align: center;
  font-size: $lg-fs * 1.5;
  margin-bottom: $spacer-md * 1.25;
}

.video-box {
  position: relative;
  border-radius: 15px;

  video,
  .react-player__preview {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 15px;
  }
}

.video-box-animation {
  @include fade-in(500ms, 100ms);
}

.contact-box {
  background: white;
  padding: $spacer * 1.25 $spacer;
  width: 35%;
  border-radius: 15px;
  box-shadow: 2px 4px 10px 3px rgba(0, 0, 0, 0.15);
}

.contact-box-animation {
  @include slide-in(1500ms, 600ms);
}

.contact-box__title {
  color: $dark-blue;
  font-family: $mplus-font;
  font-size: $md-fs;
  font-weight: 900;
  margin-bottom: $spacer;
}

.contact-box__info {
  color: $dark-blue;
  font-family: $mplus-font;
  font-size: $sm-fs * 1.15;
  margin-bottom: $spacer-small;
}

.contact-box__or {
  color: $dark-blue;
  font-family: $mplus-font;
  font-size: $md-fs;
  font-weight: 900;
  margin: $spacer * 1.25 0;
}

.contact-box__text {
  color: $dark-blue;
  font-family: $mplus-font;
  font-size: $sm-fs * 1.25;
  text-align: center;
  line-height: $spacer;
}

.contact-box__link {
  outline: none;
  border: none;
  background: $blue;
  color: white;
  font-size: $sm-fs;
  font-weight: 700;
  font-family: $mplus-font;
  padding: $spacer-small * 0.75 $spacer-md;
  border-radius: 25px;
  margin: $spacer 0;
}

// About the Provider ---- FROM OLD VERSION OF WEBSITE
.about-provider {
  margin-bottom: $spacer-lg * 3.5;
}

.about-provider__underline {
  transform: scale(0.65, 0.75);
  margin-bottom: $spacer-md * 1.5;
}

.about-provider__title {
  color: $dark-blue;
  font-size: $lg-fs * 1.25;
  font-family: $mplus-font;
  font-weight: 900;
  margin-bottom: $spacer-small;
}

.provider-content {
  align-items: flex-start;
}

.provider-content__img {
  width: fit-content;
  height: 35vh;
  border-radius: 15px;
  margin-right: $spacer-md;
  object-fit: contain;
}

.provider-info {
  &__text {
    color: $dark-blue;
    line-height: $spacer * 1.25;
    max-width: 70ch;
    margin-bottom: $spacer-md * 1.5;
  }

  &__link {
    color: $orange;
    font-family: $mplus-font;
    font-size: $sm-fs * 1.15;
    font-weight: 700;
    border: 2px solid $orange;
    text-decoration: none;
    padding: $spacer-small * 0.5 $spacer-md;
    border-radius: 25px;
    transition: all 200ms ease-out;

    &:hover {
      cursor: pointer;
      background: $orange;
      color: white;
    }
  }
}

// Media Queriies
@import "./mq";
