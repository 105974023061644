// Not Found Page Styles
.not-found-page {
  min-height: 100vh;

  .top-bg {
    margin-bottom: $spacer-md * 1.75;
  }
}

.not-found__title,
.not-found__text {
  text-align: center;
  z-index: 100;
  color: $dark-blue;
}

.not-found__title {
  font-size: $lg-fs * 3;
  font-family: $bubble-font;
  margin-bottom: $spacer-small;
}

.not-found__text {
  font-size: $lg-fs;
  font-family: $mplus-font;
  font-weight: 500;
}

.not-found__home {
  position: relative;
  text-decoration: none;
  background: $dark-blue;
  color: white;
  font-size: $sm-fs * 1.25;
  font-weight: 700;
  font-family: $mplus-font;
  padding: $spacer-small $spacer-md;
  border-radius: 25px;

  // https://codepen.io/anon/embed/emgQKJ?height=500&theme-id=1&slug-hash=emgQKJ&default-tab=result#css-box
  border: 0 solid;
  box-shadow: inset 0 0 20px rgba($dark-blue, 0);
  outline: 1px solid;
  outline-color: rgba($dark-blue, 0.5);
  outline-offset: 0px;
  text-shadow: none;
  transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);

  &:hover {
    box-shadow: inset 0 0 20px rgba($dark-blue, 0.5),
      0 0 20px rgba($dark-blue, 0.2);
    outline-color: rgba($dark-blue, 0);
    outline-offset: 15px;
    text-shadow: 1px 1px 2px #427388;
    opacity: 0.75;
  }
}

// Media Queries
@media (max-width: 435px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .not-found-page {
    .top-bg {
      margin-bottom: $spacer-md * 1.5;
    }
  }

  .not-found__title,
  .not-found__text {
    text-align: center;
  }

  .not-found__title {
    font-size: $lg-fs * 1.5;
    margin-bottom: $spacer-small;
  }

  .not-found__text {
    max-width: 90vw;
    font-size: $md-fs;
    line-height: $spacer * 1.5;
  }

  .not-found__home {
    font-size: $sm-fs;
    font-weight: 700;
    font-family: $mplus-font;
    padding: $spacer-small * 0.75 $spacer-md;
    border-radius: 25px;
  }
}
