/* 
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
Twitter: @rich_clark
*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}

/* My Code */
*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

/* Variables */
/* Colors */
/* Font */
/* Spacers */
.between-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.around-row {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.center-row {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.row {
  display: flex;
  align-items: center;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateX(50vw);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes lengthen {
  from {
    height: 3rem;
  }
  to {
    height: 10rem;
  }
}
@keyframes shorten {
  from {
    height: 10rem;
  }
  to {
    height: 3rem;
  }
}
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 1rem 12.5%;
  box-shadow: 0 1px 5px 0 rgba(210, 210, 210, 0.5);
  background: white;
  z-index: 1000;
}

.availability {
  position: absolute;
  bottom: -3.5vh;
  left: 0;
  right: 0;
  background: rgba(248, 245, 245, 0.75);
  text-align: center;
  padding: 0.5rem 0;
  font-size: 0.9rem;
  color: #000040;
}

.logo {
  margin-right: 1.5rem;
}

.logo__img {
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
}

.logo__title {
  font-family: "Bubblegum Sans", sans-serif;
  color: #000040;
  font-size: 1.5rem;
  text-decoration: none;
}

.links__link {
  color: #000040;
  font-family: "M PLUS Rounded 1c", sans-serif;
  text-decoration: none;
  margin-left: 1.5rem;
  font-weight: 600;
  font-size: 0.9rem;
}
.links__link:hover {
  color: #758bff;
}

.contact-link,
.signup-link {
  outline: none;
  border: none;
  border-radius: 25px;
  font-family: "M PLUS Rounded 1c", sans-serif;
  padding: 0.5rem 2.25rem;
  text-decoration: none;
  font-weight: 600;
  font-size: 0.9rem;
  transition: all 200ms ease-out;
}

.contact-link {
  color: white;
  background: #758bff;
  border: 2px solid #758bff;
}
.contact-link:hover {
  transform: scale(1.025);
  box-shadow: 2px 4px 10px 0 rgba(0, 0, 0, 0.15);
}

.signup-link {
  color: #000040;
  border: 2px solid #000040;
  margin-left: 1.5rem;
}
.signup-link:hover {
  background: #000040;
  color: white;
}

.mobile-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 3.125% 6.25%;
  box-shadow: 0 1px 5px 0 rgba(210, 210, 210, 0.5);
  background: white;
  z-index: 1000;
}
.mobile-navbar .logo__img {
  width: 3rem;
  height: 3rem;
}
.mobile-navbar .logo__title {
  font-size: 1.35rem;
}

.mobile-navbar__btn {
  width: 3rem;
  height: 3rem;
  outline: none;
  border: none;
  background: transparent;
}
.mobile-navbar__btn svg {
  width: 100%;
  height: 100%;
}
.mobile-navbar__btn svg path {
  fill: #000040;
}

.mobile-nav-shadow {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
}

.mobile-nav-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 80vw;
  height: 100vh;
  overflow-y: hidden;
  background: white;
  box-shadow: 0 4px 10px 5px rgba(0, 0, 0, 0.15);
  padding: 3.125% 3.125%;
}
.mobile-nav-menu .logo__img {
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 0.75rem;
}
.mobile-nav-menu .logo__title {
  font-size: 1.125rem;
}

.mobile-nav-menu__close {
  width: 2.5rem;
  height: 2.5rem;
  outline: none;
  border: none;
  background: transparent;
}
.mobile-nav-menu__close svg {
  width: 100%;
  height: 100%;
}
.mobile-nav-menu__close svg path {
  fill: #000040;
}

.mobile-nav-menu__header {
  margin-bottom: 3rem;
}

.mobile-nav-menu__links {
  display: flex;
  flex-direction: column;
}

.mobile-nav-menu__link {
  text-decoration: none;
  margin-bottom: 1.5rem;
  background: #000040;
  color: white;
  padding: 1rem 1.5rem;
  border-radius: 25px;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 600;
}

.footer {
  background: rgba(242, 242, 242, 0.9);
  padding: 3rem 12.5%;
  height: 40vh;
  align-items: flex-start;
}

.footer-left {
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
}
.footer-left .logo__img {
  width: 7.5rem;
  height: 7.5rem;
}
.footer-left .logo__title {
  font-size: 2rem;
  margin-left: 1.5rem;
}
.footer-left__link {
  text-decoration: none;
  color: #000040;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-size: 1.035rem;
}
.footer-left__link + .footer-left__link:before {
  content: " | ";
  padding: 0 1.5rem;
  color: rgba(0, 0, 89, 0.15);
}

.footer-right {
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
}

.footer-contact__title {
  color: #000040;
  font-size: 1.215rem;
  font-weight: 900;
  font-family: "M PLUS Rounded 1c", sans-serif;
  margin-bottom: 1.5rem;
}

.footer-contact__info {
  margin-bottom: 1rem;
  color: #000040;
  font-family: "M PLUS Rounded 1c", sans-serif;
}

.footer-right__copyright {
  color: #000040;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-size: 0.9rem;
}

@media (max-width: 435px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .footer {
    flex-direction: column;
    align-items: center;
    padding: 3rem 6.25%;
    height: fit-content;
  }
  .footer .logo {
    flex-direction: column;
    align-items: center;
    margin-right: 0;
  }
  .footer-left {
    align-items: center;
  }
  .footer-left .logo__img {
    margin-bottom: 1.5rem;
  }
  .footer-left .logo__title {
    font-size: 2rem;
    margin-left: 0;
    margin-bottom: 2.25rem;
  }
  .footer-left__links {
    flex-direction: column;
    margin-bottom: 3rem;
  }
  .footer-left__link {
    font-size: 1.215rem;
    margin-bottom: 1.5rem;
  }
  .footer-left__link + .footer-left__link:before {
    display: none;
  }
  .footer-right {
    align-items: center;
  }
  .footer-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;
  }
  .footer-contact__title {
    font-size: 1.215rem;
    margin-bottom: 1.5rem;
  }
  .footer-right__copyright {
    font-size: 0.945rem;
  }
}
@keyframes carousel-animate {
  0% {
    visibility: hidden;
    opacity: 0;
    transform: translateX(200%) scale(0.7);
  }
  3%, 14.2857142857% {
    visibility: visible;
    opacity: 0.8;
    transform: translateX(100%) scale(0.9);
  }
  17.2857142857%, 28.5714285714% {
    visibility: visible;
    opacity: 1;
    transform: translateX(0) scale(1);
  }
  31.5714285714%, 42.8571428571% {
    visibility: visible;
    opacity: 0.8;
    transform: translateX(-100%) scale(0.9);
  }
  45.8571428571% {
    visibility: visible;
    opacity: 0;
    transform: translateX(-200%) scale(0.9);
  }
  100% {
    visibility: hidden;
    opacity: 0;
    transform: translateX(-200%) scale(0.7);
  }
}
:root {
  --light: 0;
  --light: 0;
  --max-width-post: 420px;
  --primary: hsl(
    calc(64 + 220 * var(--light)),
    calc(77% - 7% * var(--light)),
    calc(79% - 56% * var(--light))
  );
  --bg: hsl(
    calc(284 - 220 * var(--light)),
    calc(70% + 7% * var(--light)),
    calc(23% + 56% * var(--light))
  );
  --text-primary: hsl(
    calc(60 * var(--light)),
    calc(19% * var(--light)),
    calc(97% - 89% * var(--light))
  );
  --font-size-sm: clamp(0.7rem, 0.91vw + 0.47rem, 1.2rem);
  --font-size-base: clamp(0.88rem, 1.14vw + 0.59rem, 1.5rem);
  --font-size-md: clamp(1.09rem, 1.42vw + 0.74rem, 1.88rem);
  --font-size-lg: clamp(1.37rem, 1.78vw + 0.92rem, 2.34rem);
  --font-size-xl: clamp(1.71rem, 2.22vw + 1.15rem, 2.93rem);
  --font-size-xxl: clamp(2.14rem, 2.77vw + 1.44rem, 3.66rem);
  --font-size-xxxl: clamp(2.67rem, 3.47vw + 1.8rem, 4.58rem);
}

.container {
  --container-padding-horizontal: 32px;
  position: relative;
  padding-inline: var(--container-padding-horizontal);
  display: grid;
  place-items: center;
  height: 100%;
  width: 100%;
}

.carousel {
  pointer-events: none;
  position: absolute;
  padding-block-start: 67px;
  padding-block-end: max(24px, 29px + var(--font-size-md));
  perspective: 100px;
  width: 100%;
}
@media (max-width: 568px) {
  .carousel {
    padding-block-end: 52px;
  }
}
.carousel__wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  height: 61.2vh;
}
.carousel .item {
  position: absolute;
  height: 50vh;
  box-shadow: 0 0 7.5px 2.35px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  margin-inline: var(--container-padding-horizontal);
  opacity: 0;
  will-change: transform, opacity;
  animation: carousel-animate 27s cubic-bezier(0.37, 0, 0.63, 1) infinite;
}
@media (max-width: 568px) {
  .carousel .item {
    margin-inline: calc(var(--container-padding-horizontal) + 1px);
  }
}
.carousel .item:nth-child(1) {
  animation-delay: calc(3.8571428571s * -1);
}
.carousel .item:nth-child(2) {
  animation-delay: calc(3.8571428571s * 0);
}
.carousel .item:nth-child(3) {
  animation-delay: calc(3.8571428571s * 1);
}
.carousel .item:nth-child(4) {
  animation-delay: calc(3.8571428571s * 2);
}
.carousel .item:nth-child(5) {
  animation-delay: calc(3.8571428571s * 3);
}
.carousel .item:nth-child(6) {
  animation-delay: calc(3.8571428571s * 4);
}
.carousel .item:last-child {
  animation-delay: calc(-3.8571428571s * 2);
}
.carousel img {
  width: 50vw;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}
.carousel img:hover {
  cursor: pointer;
}

.btn {
  background: hsl(var(--hue), 98%, 80%);
  cursor: pointer;
  color: black;
  letter-spacing: 0.05rem;
  overflow: hidden;
  position: relative;
}
.btn--yellow {
  --hue: 46;
}
.btn--green {
  --hue: 163;
}
.btn--purple {
  --hue: 244;
}
.btn--red {
  --hue: 0;
}
.btn--blue {
  --hue: 210;
}
.btn:active, .btn:focus {
  outline: 3px solid hsl(calc(var(--hue) + 90), 98%, 80%);
}
.btn + .btn {
  margin-top: 2.5rem;
}
.btn__txt {
  position: relative;
  z-index: 2;
}
.btn__bg {
  background: hsl(var(--hueBg), 98%, 80%);
  border-radius: 50%;
  display: block;
  height: 0;
  left: 50%;
  margin: -50% 0 0 -50%;
  padding-top: 100%;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: scale(0);
  transform-origin: 50% 50%;
  transition: transform 0.175s cubic-bezier(0.5, 1, 0.89, 1);
  z-index: 1;
}
.btn__bg:nth-of-type(1) {
  --hueBg: calc(var(--hue) - 90);
  transition-delay: 0.225s;
}
.btn__bg:nth-of-type(2) {
  --hueBg: calc(var(--hue) - 180);
  transition-delay: 0.15s;
}
.btn__bg:nth-of-type(3) {
  --hueBg: calc(var(--hue) - 270);
  transition-delay: 0.075s;
}
.btn__bg:nth-of-type(4) {
  --hueBg: calc(var(--hue) - 360);
  transition-delay: 0s;
}
.btn:hover .btn__bg, .btn:focus .btn__bg, .btn:active .btn__bg {
  transform: scale(1.5);
  transition: transform 0.35s cubic-bezier(0.11, 0, 0.5, 0);
}
.btn:hover .btn__bg:nth-of-type(1), .btn:focus .btn__bg:nth-of-type(1), .btn:active .btn__bg:nth-of-type(1) {
  transition-delay: 0.15s;
}
.btn:hover .btn__bg:nth-of-type(2), .btn:focus .btn__bg:nth-of-type(2), .btn:active .btn__bg:nth-of-type(2) {
  transition-delay: 0.3s;
}
.btn:hover .btn__bg:nth-of-type(3), .btn:focus .btn__bg:nth-of-type(3), .btn:active .btn__bg:nth-of-type(3) {
  transition-delay: 0.45s;
}
.btn:hover .btn__bg:nth-of-type(4), .btn:focus .btn__bg:nth-of-type(4), .btn:active .btn__bg:nth-of-type(4) {
  transition-delay: 0.6s;
}

.top-bg {
  position: relative;
  width: 100vw;
  height: 50vh;
  border-radius: 0px 0px 100px 100px;
  margin-bottom: 9rem;
  margin-top: 10vh;
}
.top-bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 243, 169, 0.9);
  border-radius: 0px 0px 100px 100px;
}

.top-bg__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0px 0px 100px 100px;
}

.top-bg__title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 100;
  color: #000040;
  font-size: 4.5rem;
  font-family: "Bubblegum Sans", sans-serif;
}

@media (max-width: 435px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .top-bg {
    width: 100vw;
    height: 35vh;
    border-radius: 0px 0px 25px 25px;
    margin-bottom: 3rem;
  }
  .top-bg::after {
    border-radius: 0px 0px 25px 25px;
  }
  .top-bg__img {
    border-radius: 0px 0px 25px 25px;
  }
  .top-bg__title {
    width: 100%;
    text-align: center;
    font-size: 2.625rem;
    line-height: 3rem;
  }
}
@media (max-width: 435px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .top-bg {
    margin-top: 7.5vh;
  }
}
.loading {
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2000;
}

.loader {
  width: 48px;
  height: 48px;
  margin: auto;
  position: relative;
}

.loader:before {
  content: "";
  width: 48px;
  height: 5px;
  background: #000;
  opacity: 0.25;
  position: absolute;
  top: 60px;
  left: 0;
  border-radius: 50%;
  animation: shadow 0.5s linear infinite;
}

.loader:after {
  content: "";
  width: 100%;
  height: 100%;
  background: #00cdcd;
  animation: bxSpin 0.5s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
}

@keyframes bxSpin {
  17% {
    border-bottom-right-radius: 3px;
    background: #00cdcd;
  }
  25% {
    transform: translateY(9px) rotate(22.5deg);
    background: #00e7e7;
  }
  50% {
    transform: translateY(18px) scale(1, 0.9) rotate(45deg);
    border-bottom-right-radius: 40px;
    background: #01ffff;
  }
  75% {
    transform: translateY(9px) rotate(67.5deg);
    background: #00e7e7;
  }
  100% {
    transform: translateY(0) rotate(90deg);
    background: #00cdcd;
  }
}
@keyframes shadow {
  0%, 100% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1);
  }
}
.play-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.outer_circle {
  stroke-width: 3;
  stroke-dasharray: 410;
  stroke-dashoffset: 0;
  stroke-linecap: square;
  transition: all 0.4s ease-out;
}

.play-box:hover .outer_circle {
  stroke-dashoffset: 410;
  transition: stroke 0.7s 0.4s ease-out, stroke-dashoffset 0.4s ease-out;
}

.play-box:hover .inner-circle {
  fill: #00cdcd;
  transition: fill 0.4s 0.3s ease-out;
}

.play-box:hover .play {
  fill: white;
  transition: fill 0.4s 0.3s ease-out;
}

.nav-btn-box {
  min-width: 3rem;
  height: 3rem;
  position: fixed;
  bottom: 1vw;
  right: 1vw;
  z-index: 1000;
  background: #a475ff;
  border-radius: 15px;
  box-shadow: 0 0 15px 5px rgba(164, 117, 255, 0.75);
  transition: all 200ms ease-out;
  animation: shorten forwards 500ms ease-out;
}
.nav-btn-box:hover {
  transform: scale(1.1);
}

.nav-btn-box__toggle {
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  background: transparent;
  padding: 0;
}
.nav-btn-box__toggle .short-left-arrow path {
  fill: white;
  transition: all 200ms ease-out;
  animation: fade-in forwards 250ms ease-in 600ms;
  opacity: 0;
}
.nav-btn-box__toggle .close {
  width: 1.25rem;
  transition: all 200ms ease-out;
}
.nav-btn-box__toggle .close path {
  fill: white;
}
.nav-btn-box__toggle:hover {
  cursor: pointer;
}
.nav-btn-box__toggle:hover .short-left-arrow path {
  fill: #fff3a9;
}

.nav-btn-box__active {
  flex-direction: column;
  animation: lengthen forwards 500ms ease-out;
}
.nav-btn-box__active .nav-btn-box__toggle {
  width: 3rem;
  height: 3rem;
  margin-bottom: 1.5rem;
  animation: fade-in forwards 250ms ease-in 600ms;
  opacity: 0;
}
.nav-btn-box__active .nav-btn-box__toggle:hover .close path {
  fill: #fff3a9;
}
.nav-btn-box__active:hover {
  transform: scale(1);
}

.nav-btns {
  width: 2rem;
  height: 2rem;
  outline: none;
  border: none;
  background: #e975ff;
  border-radius: 5px;
  margin-bottom: 1rem;
  transition: all 200ms ease-out;
  animation: fade-in forwards 250ms ease-in 600ms;
  opacity: 0;
}
.nav-btns .short-up-arrow path,
.nav-btns .short-down-arrow path {
  fill: white;
}
.nav-btns:hover {
  cursor: pointer;
  transform: scale(1.1);
}

@media (max-width: 435px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .nav-btn-box {
    bottom: 2vw;
    right: 2vw;
    box-shadow: 0 0 15px 2.5px rgba(164, 117, 255, 0.75);
  }
  .nav-btn-box:hover {
    transform: scale(1);
  }
}
.about-info {
  position: relative;
  align-items: flex-start;
  background: #a3ff8a;
  height: 130vh;
  border-radius: 100px;
  margin-bottom: 6rem;
}

.about-info__star {
  width: 15rem;
  height: 15rem;
  position: absolute;
  bottom: 2.5vh;
  left: 3.5vw;
}

.about-desc__puzzle {
  width: 12.5rem;
  height: 12.5rem;
  position: absolute;
  top: 2.5vh;
  left: 2.5vw;
}

.about-desc {
  position: relative;
  width: 47.5%;
  min-height: 75%;
  background: #8affac;
  padding-top: 275px;
  padding-right: 3rem;
  padding-left: 9.375%;
  border-radius: 100px 0px 200px 0px;
}

.about-desc__title {
  color: #000040;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 800;
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.about-desc__text {
  width: 50ch;
  color: #000040;
  font-family: "M PLUS Rounded 1c", sans-serif;
  line-height: 1.875rem;
  font-weight: 500;
  margin-bottom: 4.5rem;
}

.about-desc__link {
  width: fit-content;
  background: #eee8aa;
  color: #000040;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 700;
  text-decoration: none;
  padding: 0.75rem 3rem;
  border-radius: 35px;
  font-size: 1.035rem;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  transition: all 200ms ease-out;
}
.about-desc__link:hover {
  box-shadow: 4px 8px 4px rgba(0, 0, 0, 0.25);
}
.about-desc__link .right-arrow {
  transform: scale(1.25);
  margin-left: 0.75rem;
}
.about-desc__link .right-arrow path {
  fill: #000040;
}

.about-cards {
  position: relative;
  display: grid;
  grid-template-columns: 25% 25%;
  column-gap: 13.5rem;
  row-gap: 6rem;
  grid-auto-rows: 40% 40%;
  width: 50%;
  min-height: 100%;
  padding: 9rem 4.5rem;
}

.about-cards__card {
  position: relative;
  width: 20vw;
  height: fit-content;
  background: white;
  border-radius: 25px;
  padding: 1.5rem;
  padding-top: 4.95rem;
  padding-bottom: 1.875rem;
}

.about-cards__title {
  color: #000040;
  font-size: 1.35rem;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 900;
  line-height: 1.5rem;
  margin-bottom: 0.75rem;
}

.about-cards__info {
  color: black;
  font-size: 1.035rem;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 500;
  line-height: 1.5rem;
}

.about-cards__icon {
  position: absolute;
  top: -1.25rem;
  left: -1.25rem;
  width: 5rem;
  height: 5rem;
  background: white;
  border-radius: 50%;
}
.about-cards__icon img {
  width: 55%;
  height: 55%;
}

#female-teacher {
  background: #eee8aa;
  box-shadow: 3px 4px 7.5px 0px rgba(0, 0, 0, 0.25);
}
#female-teacher .about-cards__icon {
  background: #f7f4d5;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
}

#health,
#shapes {
  transform: translateY(6rem);
}

#language img {
  transform: rotate(-21deg);
}

.about-cards-animation #female-teacher {
  animation: fade-in forwards 550ms ease-in 200ms;
  opacity: 0;
}
.about-cards-animation #health {
  animation: fade-in forwards 550ms ease-in 750ms;
  opacity: 0;
}
.about-cards-animation #language {
  animation: fade-in forwards 550ms ease-in 1500ms;
  opacity: 0;
}
.about-cards-animation #shapes {
  animation: fade-in forwards 550ms ease-in 2250ms;
  opacity: 0;
}

.home-page {
  position: relative;
  padding-top: 10vh;
}

.yellow-rect {
  position: absolute;
  top: 12.5vh;
  left: -29.5%;
  z-index: -1;
  width: 500px;
  height: 500px;
  transform: rotate(70deg);
  flex-shrink: 0;
  border-radius: 95px;
  background: rgba(255, 243, 169, 0.8);
  box-shadow: 0px 0px 7.5px 5px rgba(0, 0, 0, 0.05);
}

.green-rect {
  position: absolute;
  top: -30vh;
  right: -15vw;
  z-index: -1;
  width: 847.917px;
  height: 800px;
  transform: rotate(39.639deg);
  flex-shrink: 0;
  border-radius: 250px 250px 150px 250px;
  background: #a3ff8a;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.15);
}

.hero {
  margin-top: 15vh;
  z-index: 100;
  padding: 0 12.5%;
}

.hero-content {
  width: 30vw;
}

.hero-content__title {
  color: #000040;
  font-family: "Bubblegum Sans", sans-serif;
  font-size: 4rem;
  margin-bottom: 1.5rem;
}

.hero-content__text {
  color: #000040;
  font-family: "M PLUS Rounded 1c", sans-serif;
  line-height: 1.875rem;
  margin-bottom: 3rem;
}

.hero__img {
  width: 550px;
  height: 500px;
  border-radius: 50px 5px;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.25);
  object-fit: cover;
  opacity: 0;
  transform: translateX(50vw);
  animation: slide-in forwards 1000ms ease-out 0ms;
}

.inside-images {
  margin-top: 15rem;
}

.bg-svg {
  width: 100vw;
  transform: scale(1.5);
}

.inside-images-content {
  background: #758bff;
  padding: 6rem 0;
  z-index: 10;
  min-height: 90vh;
  border-radius: 0px 0px 50px 50px;
  margin-bottom: 9rem;
}

.inside-images-content__title {
  color: white;
  font-weight: 900;
  font-family: "M PLUS Rounded 1c", sans-serif;
  text-align: center;
  font-size: 3rem;
}

.schedule-visit {
  margin-top: 9rem;
  margin-bottom: 9rem;
}
.schedule-visit .red-wave {
  width: 100vw;
  transform: scale(1.1);
}
.schedule-visit .reverse {
  transform: scale(1.1) rotate(180deg);
}

.sv-content {
  background: #ff758b;
  padding: 0 12.5%;
  z-index: 100;
  height: 70vh;
}
.sv-content .between-row {
  align-items: flex-start;
}

.sv-content__title {
  color: white;
  font-weight: 900;
  font-family: "M PLUS Rounded 1c", sans-serif;
  text-align: center;
  font-size: 3rem;
  margin-bottom: 3.75rem;
}

.video-box {
  position: relative;
  border-radius: 15px;
}
.video-box video,
.video-box .react-player__preview {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 15px;
}

.video-box-animation {
  animation: fade-in forwards 500ms ease-in 100ms;
  opacity: 0;
}

.contact-box {
  background: white;
  padding: 1.875rem 1.5rem;
  width: 35%;
  border-radius: 15px;
  box-shadow: 2px 4px 10px 3px rgba(0, 0, 0, 0.15);
}

.contact-box-animation {
  opacity: 0;
  transform: translateX(50vw);
  animation: slide-in forwards 1500ms ease-out 600ms;
}

.contact-box__title {
  color: #000040;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-size: 1.5rem;
  font-weight: 900;
  margin-bottom: 1.5rem;
}

.contact-box__info {
  color: #000040;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-size: 1.035rem;
  margin-bottom: 1rem;
}

.contact-box__or {
  color: #000040;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-size: 1.5rem;
  font-weight: 900;
  margin: 1.875rem 0;
}

.contact-box__text {
  color: #000040;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-size: 1.125rem;
  text-align: center;
  line-height: 1.5rem;
}

.contact-box__link {
  outline: none;
  border: none;
  background: #758bff;
  color: white;
  font-size: 0.9rem;
  font-weight: 700;
  font-family: "M PLUS Rounded 1c", sans-serif;
  padding: 0.75rem 3rem;
  border-radius: 25px;
  margin: 1.5rem 0;
}

.about-provider {
  margin-bottom: 21rem;
}

.about-provider__underline {
  transform: scale(0.65, 0.75);
  margin-bottom: 4.5rem;
}

.about-provider__title {
  color: #000040;
  font-size: 2.5rem;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 900;
  margin-bottom: 1rem;
}

.provider-content {
  align-items: flex-start;
}

.provider-content__img {
  width: fit-content;
  height: 35vh;
  border-radius: 15px;
  margin-right: 3rem;
  object-fit: contain;
}

.provider-info__text {
  color: #000040;
  line-height: 1.875rem;
  max-width: 70ch;
  margin-bottom: 4.5rem;
}
.provider-info__link {
  color: #ffb42c;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-size: 1.035rem;
  font-weight: 700;
  border: 2px solid #ffb42c;
  text-decoration: none;
  padding: 0.5rem 3rem;
  border-radius: 25px;
  transition: all 200ms ease-out;
}
.provider-info__link:hover {
  cursor: pointer;
  background: #ffb42c;
  color: white;
}

@media (max-width: 435px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .home-page {
    padding-top: 0;
  }
  .yellow-rect {
    display: none;
  }
  .green-rect {
    top: -30vh;
    right: -100vw;
    width: 600px;
    height: 600px;
  }
  .hero {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    padding: 0 6.25%;
    margin-top: 20vh;
  }
  .hero-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100vw;
  }
  .hero-content__title {
    font-size: 3rem;
    line-height: 3.45rem;
    text-align: center;
  }
  .hero-content__text {
    max-width: 90vw;
    line-height: 1.5rem;
    margin-bottom: 3rem;
    text-align: center;
  }
  .hero__img {
    width: 70vw;
    height: 60vw;
    border-radius: 25px 5px;
    margin-bottom: 3rem;
  }
  .inside-images {
    margin-top: 7.5rem;
  }
  .bg-svg {
    display: none;
  }
  .inside-images-content {
    padding: 3rem 0;
    min-height: 70vh;
    border-radius: 0;
    margin-bottom: 6rem;
  }
  .inside-images-content .item {
    width: 90vw;
    height: 30vh;
  }
  .inside-images-content .carousel__img {
    width: 100%;
    height: 100%;
  }
  .inside-images-content .carousel {
    padding-top: 3rem;
  }
  .inside-images-content__title {
    max-width: 70vw;
    text-align: center;
    font-size: 2.5rem;
    line-height: 3rem;
    margin: 0 auto;
  }
  .about-info {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: fit-content;
    height: 290vh;
    border-radius: 0;
    margin-bottom: 6rem;
  }
  .about-info__star {
    display: none;
  }
  .about-desc__puzzle {
    display: none;
  }
  .about-desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 85vh;
    background: #8affac;
    padding-top: 4.5rem;
    padding-right: 6.25%;
    padding-left: 6.25%;
    border-radius: 0;
    margin-bottom: 3rem;
  }
  .about-desc__title {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 1.5rem;
  }
  .about-desc__text {
    width: 90vw;
    text-align: center;
    line-height: 1.875rem;
    margin-bottom: 3rem;
  }
  .about-desc__link {
    width: fit-content;
    padding: 0.75rem 3rem;
    font-size: 1.035rem;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  }
  .about-desc__link:hover {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  }
  .about-cards {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0;
    column-gap: 0;
    width: 100%;
    min-height: 100%;
    padding: 1.5rem 6.25%;
  }
  .about-cards__title,
  .about-cards__info {
    text-align: center;
  }
  .about-cards__card {
    width: 90vw;
    margin-bottom: 4.5rem;
  }
  .about-cards__icon {
    top: -1.25rem;
    left: 50%;
    transform: translateX(-50%);
  }
  #female-teacher {
    box-shadow: 0 0 7.5px 0px rgba(0, 0, 0, 0.25);
  }
  #female-teacher .about-cards__icon {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  }
  #health,
  #shapes {
    transform: translateY(0);
  }
  #language img {
    transform: rotate(-21deg);
  }
  .schedule-visit {
    margin-top: 7.5rem;
    margin-bottom: 6rem;
  }
  .schedule-visit .red-wave {
    display: none;
  }
  .sv-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ff758b;
    padding: 3rem 6.25%;
    z-index: 100;
    min-height: 125vh;
  }
  .sv-content .between-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: flex-start;
  }
  .sv-content__title {
    font-size: 2rem;
    line-height: 2.625rem;
    margin-bottom: 3rem;
  }
  .video-box {
    position: relative;
    width: 90vw;
    height: 30vh;
    border-radius: 15px;
    margin-bottom: 3rem;
  }
  .video-box video,
  .video-box .react-player__preview {
    transform: scale(1.05);
    border-radius: 15px;
  }
  .contact-box {
    width: 100%;
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.15);
  }
  .contact-box__link {
    padding: 0.5rem 3rem;
  }
  .about-provider {
    margin-bottom: 12rem;
  }
  .about-provider__underline {
    display: none;
  }
  .about-provider__title {
    max-width: 90vw;
    font-size: 2.3rem;
    margin-bottom: 3rem;
    text-align: center;
  }
  .provider-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .provider-content__img {
    margin-right: 0;
    border-radius: 15px !important;
    margin-bottom: 3rem;
  }
  .provider-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .provider-info__text {
    max-width: 85vw;
    margin-bottom: 3.75rem;
    text-align: center;
  }
  .provider-info__link {
    background: #ffb42c;
    color: white;
  }
  .provider-info__link:hover {
    background: #ffb42c;
    color: white;
  }
}
@media (max-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}
@media (max-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}
@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}
.about-dc-provider {
  padding: 0 12.5%;
  align-items: flex-start;
  margin-bottom: 9rem;
}

.about-dc-provider__img {
  width: fit-content;
  height: 75vh;
  border-radius: 15px;
  margin-right: 3rem;
  object-fit: contain;
}

.about-dc-provider__title,
.about-daycare__title {
  color: #000040;
  font-size: 2rem;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 900;
  margin-bottom: 1.25rem;
}

.about-dc-provider__text,
.about-daycare__text {
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  width: 40ch;
  line-height: 2.025rem;
}

.about-dc-provider__text {
  width: 40ch;
  font-size: 1.215rem;
  line-height: 2.25rem;
}

.about-daycare {
  position: relative;
  width: 80vw;
  background: rgba(255, 243, 169, 0.95);
  padding: 6rem 0;
  padding-left: 12.5%;
  padding-right: 20vw;
  border-radius: 0px 100px 100px 0px;
  margin-bottom: 9rem;
  box-shadow: 0px 0px 15px 2.5px rgba(0, 0, 0, 0.15);
}

.about-daycare__img {
  position: absolute;
  width: 90vw;
  height: 80%;
  right: -50vw;
  border-radius: 100px 0px 0px 100px;
  z-index: -1;
}

.about-daycare__text {
  width: 70ch;
  margin-bottom: 1.5rem;
}

.about-video__title {
  color: #000040;
  text-align: center;
  font-size: 2.5rem;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 900;
  margin-bottom: 2.25rem;
}

.about-video__box {
  position: relative;
  border-radius: 15px;
  margin-bottom: 18rem;
}
.about-video__box video,
.about-video__box .react-player__preview {
  border-radius: 15px;
}

@media (max-width: 435px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .about-dc-provider {
    padding: 0 6.25%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 6rem;
  }
  .about-dc-provider__img {
    margin-right: 0;
    border-radius: 15px !important;
    margin-bottom: 3rem;
  }
  .about-dc-provider__title,
  .about-daycare__title {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 1.25rem;
  }
  .about-dc-provider__text,
  .about-daycare__text {
    text-align: center;
    font-size: 1.035rem;
    width: 90vw;
  }
  .about-dc-provider__text {
    width: 90vw;
    font-size: 1.035rem;
    line-height: 2.25rem;
  }
  .about-daycare {
    width: 100vw;
    background: #fff3a9;
    padding: 3rem 0;
    padding-left: 6.25%;
    padding-right: 6.25%;
    border-radius: 0;
    margin-bottom: 6rem;
    box-shadow: 0px 0px 15px 2.5px rgba(0, 0, 0, 0.15);
  }
  .about-daycare__img {
    display: none;
  }
  .about-daycare__text {
    width: 100%;
    margin-bottom: 1.5rem;
  }
  .about-video__title {
    font-size: 2.3rem;
    margin-bottom: 2.025rem;
  }
  .about-video__box {
    position: relative;
    width: 100vw;
    height: 35vh;
    margin-bottom: 12rem;
  }
  .about-video__box video,
  .about-video__box .react-player__preview {
    transform: scale(0.95);
    position: absolute;
    top: 0;
    width: 100vw !important;
    height: 35vh !important;
  }
}
.not-found-page {
  min-height: 100vh;
}
.not-found-page .top-bg {
  margin-bottom: 5.25rem;
}

.not-found__title,
.not-found__text {
  text-align: center;
  z-index: 100;
  color: #000040;
}

.not-found__title {
  font-size: 6rem;
  font-family: "Bubblegum Sans", sans-serif;
  margin-bottom: 1rem;
}

.not-found__text {
  font-size: 2rem;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 500;
}

.not-found__home {
  position: relative;
  text-decoration: none;
  background: #000040;
  color: white;
  font-size: 1.125rem;
  font-weight: 700;
  font-family: "M PLUS Rounded 1c", sans-serif;
  padding: 1rem 3rem;
  border-radius: 25px;
  border: 0 solid;
  box-shadow: inset 0 0 20px rgba(0, 0, 64, 0);
  outline: 1px solid;
  outline-color: rgba(0, 0, 64, 0.5);
  outline-offset: 0px;
  text-shadow: none;
  transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
}
.not-found__home:hover {
  box-shadow: inset 0 0 20px rgba(0, 0, 64, 0.5), 0 0 20px rgba(0, 0, 64, 0.2);
  outline-color: rgba(0, 0, 64, 0);
  outline-offset: 15px;
  text-shadow: 1px 1px 2px #427388;
  opacity: 0.75;
}

@media (max-width: 435px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .not-found-page .top-bg {
    margin-bottom: 4.5rem;
  }
  .not-found__title,
  .not-found__text {
    text-align: center;
  }
  .not-found__title {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  .not-found__text {
    max-width: 90vw;
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
  .not-found__home {
    font-size: 0.9rem;
    font-weight: 700;
    font-family: "M PLUS Rounded 1c", sans-serif;
    padding: 0.75rem 3rem;
    border-radius: 25px;
  }
}
.contact-page {
  min-height: 100vh;
}
.contact-page .top-bg {
  margin-bottom: 5.25rem;
}

.contact-page__content {
  padding: 0 15.625%;
}

.contact-page__title {
  text-align: center;
  color: #000040;
  font-size: 3rem;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 900;
  margin-bottom: 4.5rem;
}

.contact-form {
  width: 100%;
  min-height: 50vh;
  margin-bottom: 6rem;
}
.contact-form .row {
  align-items: flex-start;
  width: 100%;
}

.contact-form__left {
  margin-right: 3rem;
  width: 40%;
}

.contact-form__right {
  margin-right: 3rem;
  width: 60%;
}

.contact-form__input,
.contact-form__textarea {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: "M PLUS Rounded 1c", sans-serif;
  margin-bottom: 1.5rem;
}

.contact-form__textarea {
  height: 50vh;
}

.contact-form__label {
  font-weight: 700;
  font-size: 1.125rem;
  margin-bottom: 0.75rem;
}

.contact-form__input input,
.contact-form__textarea textarea {
  width: 100%;
  font-size: 1.125rem;
  outline: none;
  padding: 0.5rem 0.75rem;
  border-radius: 5px;
  border: 1px solid #d0d0d0;
  background: #fff;
  transition: all 200ms ease-out;
}
.contact-form__input input:focus,
.contact-form__textarea textarea:focus {
  border-color: #00cdcd;
}

.contact-form__textarea textarea {
  font-size: 1.035rem;
  height: 100%;
  padding: 1.25rem;
  resize: none;
  line-height: 1.875rem;
}

.contact-form__submit {
  outline: none;
  border: none;
  background: #00cdcd;
  color: white;
  font-size: 1.035rem;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 700;
  padding: 0.5rem 3.75rem;
  border-radius: 25px;
  margin-top: 1.5rem;
  transition: all 200ms ease-out;
}
.contact-form__submit:hover {
  cursor: pointer;
  opacity: 0.75;
}

.contact-form__ch-count {
  color: grey;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 500;
  text-align: right;
}

.contact-form__error input,
.contact-form__error textarea {
  border-color: #ff758b;
}

.contact-form__error-msg {
  display: flex;
  align-items: center;
  background: rgba(255, 117, 139, 0.25);
  color: #ff758b;
  font-weight: 500;
  padding: 0.5rem 0.75rem;
  border-radius: 0 0 7.5px 7.5px;
}
.contact-form__error-msg svg {
  margin-right: 0.5rem;
}

.contact-form__error-textarea {
  border-radius: 7.5px;
}

.contact-page__error-container,
.contact-page__success-container {
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2000;
}

.contact-page__error-box,
.contact-page__success-box {
  width: 25vw;
  background: white;
  border-radius: 25px;
}

.contact-page__error-content,
.contact-page__success-content {
  padding: 1.5rem 1.875rem;
  font-family: "M PLUS Rounded 1c", sans-serif;
}

.contact-page__error-header,
.contact-page__success-header {
  background: #ff4e4e;
  padding: 1.5rem 0;
  border-radius: 25px 25px 0 0;
}
.contact-page__error-header svg,
.contact-page__success-header svg {
  transform: scale(1.5);
}

.contact-page__error-title,
.contact-page__success-title {
  font-size: 1.875rem;
  color: #000040;
  font-weight: 800;
  margin-bottom: 1rem;
}

.contact-page__error-msg,
.contact-page__success-msg {
  color: #000040;
  font-size: 0.9rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 2.25rem;
  line-height: 1.25rem;
}

.contact-page__error-close,
.contact-page__success-close {
  outline: none;
  border: none;
  background: #ff4e4e;
  color: white;
  font-size: 0.9rem;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: 700;
  padding: 0.25rem 1.875rem;
  border-radius: 25px;
  transition: all 200ms ease-out;
}
.contact-page__error-close:hover,
.contact-page__success-close:hover {
  cursor: pointer;
  opacity: 0.75;
}

.contact-page__success-header,
.contact-page__success-close {
  background: #5bd75b;
}

.contact-sv {
  width: 100%;
  align-items: flex-start;
  border-radius: 5px;
  background: white;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.05);
  margin-bottom: 12rem;
  padding: 1.875rem;
  font-family: "M PLUS Rounded 1c", sans-serif;
}

.contact-sv__title {
  color: #000040;
  font-size: 1.875rem;
  font-weight: 900;
  margin-bottom: 1rem;
}

.contact-sv__text {
  color: #000040;
  font-size: 1.125rem;
  max-width: 45ch;
  line-height: 1.875rem;
  margin-right: 3rem;
}

.contact-details {
  border-left: 2px solid #eee;
  padding-left: 3rem;
}

.contact-details__title {
  color: #000040;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.contact-details__info {
  border: 2px solid #00cdcd;
  border-radius: 0 25px 25px 0;
  padding: 0.5rem 1rem;
  padding-right: 3rem;
  margin-bottom: 1rem;
  color: #00cdcd;
  font-weight: 700;
}
.contact-details__info svg {
  margin-right: 0.75rem;
}

@media (max-width: 435px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .contact-page .top-bg {
    margin-bottom: 3.75rem;
  }
  .contact-page__content {
    padding: 0 6.25%;
  }
  .contact-page__title {
    font-size: 2rem;
    margin-bottom: 3rem;
    line-height: 2.25rem;
  }
  .contact-form {
    width: 100%;
    min-height: 50vh;
    margin-bottom: 6rem;
  }
  .contact-form .row {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .contact-form__left {
    margin-right: 0;
    width: 100%;
    margin-bottom: 1.5rem;
  }
  .contact-form__right {
    margin-right: 0;
    width: 100%;
    margin-bottom: 1.5rem;
  }
  .contact-form__input input,
  .contact-form__textarea textarea {
    font-size: 1.035rem;
  }
  .contact-form__textarea textarea {
    font-size: 0.945rem;
    padding: 1rem;
    line-height: 1.5rem;
  }
  .contact-form__submit:hover {
    opacity: 1;
  }
  .contact-form__ch-count {
    text-align: left;
  }
  .textarea__subtext {
    display: flex;
    align-items: flex-start;
    flex-direction: column-reverse;
  }
  .textarea__subtext .contact-form__error-msg {
    margin-top: 1rem;
  }
  .contact-page__error-box,
  .contact-page__success-box {
    width: 80vw;
  }
  .contact-sv {
    flex-direction: column;
    align-items: center;
    padding: 1.875rem;
  }
  .contact-sv__title {
    font-size: 1.725rem;
    margin-bottom: 0.75rem;
    text-align: center;
    line-height: 2.25rem;
  }
  .contact-sv__text {
    font-size: 1.035rem;
    max-width: 45ch;
    margin-right: 0;
    text-align: center;
    margin-bottom: 2.25rem;
  }
  .contact-details {
    border-left: none;
    border-top: 2px solid #eee;
    padding-top: 2.25rem;
    padding-left: 0;
  }
  .contact-details__title {
    text-align: center;
  }
  .contact-details__info {
    border-radius: 25px;
    padding: 0.5rem 2.25rem;
    padding-right: 2.25rem;
    text-align: center;
  }
  .contact-details__info svg {
    margin-right: 0.5rem;
  }
}
.program-page {
  min-height: 100vh;
}
.program-page .top-bg {
  margin-bottom: 6rem;
}
.program-page main {
  padding: 0 12.5%;
  font-family: "M PLUS Rounded 1c", sans-serif;
}

.program-desc {
  align-items: flex-start;
}

.program-desc__title,
.sh-content__title,
.ms-content__title {
  color: #000040;
  font-size: 2rem;
  font-weight: 900;
  margin-bottom: 1.5rem;
}

.program-desc__text,
.ms-content__text {
  max-width: 70ch;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 2.025rem;
}

.program-desc__img {
  width: 25vw;
  height: 25vw;
  border-radius: 25px;
  margin-left: 6rem;
  box-shadow: 0 0 10px 2.5px rgba(0, 0, 0, 0.25);
}

.sh-content {
  margin-bottom: 6rem;
}

.sh-content__sub-heading {
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.sh-content__sub-heading-2 {
  font-size: 1.125rem;
  font-weight: 500;
  color: #808080;
  margin-bottom: 3rem;
}

.sh-cards__card {
  width: 25vw;
  height: 70vh;
  background: white;
  border: 1px solid #eee;
  padding: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 3rem;
}

.sh-cards__card-text {
  margin-bottom: 1.5rem;
}

.sh-cards__card-time {
  font-size: 1.035rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.sh-cards__card-activity {
  font-size: 1.125rem;
  font-weight: 800;
  line-height: 1.5rem;
}

.sh-cards__msg {
  color: #808080;
  text-align: center;
  font-size: 1.035rem;
  font-weight: 500;
  line-height: 1.5rem;
}

.sh-cards__card-heading {
  font-size: 1.35rem;
  font-weight: 800;
  margin-bottom: 0.75rem;
}

.sh-cards__card-sub-heading {
  color: #808080;
  font-size: 1.035rem;
  font-weight: 400;
  margin-bottom: 1.725rem;
}

.sh-cards__card-holiday {
  font-size: 1.035rem;
  font-weight: 800;
  margin-bottom: 1.225rem;
}

.ms-content {
  margin-bottom: 12rem;
}

.ms-content__text {
  max-width: 50ch;
  margin-bottom: 1.75rem;
}

.ms-content__bold {
  font-size: 1.125rem;
  font-weight: 800;
  margin-bottom: 0.25rem;
}

@media (max-width: 435px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .program-page .top-bg {
    margin-bottom: 3rem;
  }
  .program-page main {
    padding: 0 6.25%;
  }
  .program-desc {
    align-items: center;
    flex-direction: column-reverse;
    margin-bottom: 6rem;
  }
  .program-desc__title,
  .sh-content__title,
  .ms-content__title {
    text-align: center;
  }
  .program-desc__text,
  .ms-content__text {
    text-align: center;
  }
  .program-desc__img {
    width: 20rem;
    height: 20rem;
    margin-left: 0;
    margin-bottom: 3rem;
  }
  .sh-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;
  }
  .sh-content__sub-heading {
    text-align: center;
    line-height: 1.5rem;
  }
  .sh-content__sub-heading-2 {
    max-width: 70vw;
    text-align: center;
    margin-bottom: 3rem;
  }
  .sh-cards {
    flex-direction: column;
  }
  .sh-cards__card {
    width: 100%;
    height: fit-content;
    margin-right: 0;
    margin-bottom: 3rem;
  }
  .sh-cards__msg {
    margin-top: 2.25rem;
  }
  .ms-content {
    margin-bottom: 12rem;
  }
  .ms-content__text {
    text-align: center;
  }
  .ms-content__bold {
    text-align: center;
  }
}
body,
.App {
  position: relative;
  overflow-x: hidden;
}/*# sourceMappingURL=main.css.map */