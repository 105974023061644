// About Page Styles

// About Daycare Provider
.about-dc-provider {
  padding: 0 $padding;
  align-items: flex-start;
  margin-bottom: $spacer-lg * 1.5;
}

.about-dc-provider__img {
  width: fit-content;
  height: 75vh;
  border-radius: 15px;
  margin-right: $spacer-md;
  object-fit: contain;
}

.about-dc-provider__title,
.about-daycare__title {
  color: $dark-blue;
  font-size: $lg-fs;
  font-family: $mplus-font;
  font-weight: 900;
  margin-bottom: $spacer-small * 1.25;
}

.about-dc-provider__text,
.about-daycare__text {
  font-family: $mplus-font;
  font-size: $sm-fs * 1.25;
  font-weight: 500;
  width: 40ch;
  line-height: $spacer * 1.35;
}

.about-dc-provider__text {
  width: 40ch;
  font-size: $sm-fs * 1.35;
  line-height: $spacer * 1.5;
}

// About Daycare
.about-daycare {
  position: relative;
  width: 80vw;
  background: rgba($gold, 0.95);
  padding: $spacer-lg 0;
  padding-left: $padding;
  padding-right: 20vw;
  border-radius: 0px 100px 100px 0px;
  margin-bottom: $spacer-lg * 1.5;
  box-shadow: 0px 0px 15px 2.5px rgba(0, 0, 0, 0.15);
}

.about-daycare__img {
  position: absolute;
  width: 90vw;
  height: 80%;
  right: -50vw;
  border-radius: 100px 0px 0px 100px;
  z-index: -1;
}

.about-daycare__text {
  width: 70ch;
  margin-bottom: $spacer;
}

// About Video
.about-video__title {
  color: $dark-blue;
  text-align: center;
  font-size: $lg-fs * 1.25;
  font-family: $mplus-font;
  font-weight: 900;
  margin-bottom: $spacer * 1.5;
}

.about-video__box {
  position: relative;
  border-radius: 15px;
  margin-bottom: $spacer-lg * 3;

  video,
  .react-player__preview {
    border-radius: 15px;
  }
}

// Media Queries
@import "./mq";
