// Media Queries
@media (max-width: 435px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .contact-page {
    .top-bg {
      margin-bottom: $spacer-md * 1.25;
    }
  }

  .contact-page__content {
    padding: 0 $m-padding;
  }

  .contact-page__title {
    font-size: $lg-fs;
    margin-bottom: $spacer-md;
    line-height: $spacer * 1.5;
  }

  .contact-form {
    width: 100%;
    min-height: 50vh;
    margin-bottom: $spacer-lg;

    .row {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  }

  .contact-form__left {
    margin-right: 0;
    width: 100%;
    margin-bottom: $spacer;
  }

  .contact-form__right {
    margin-right: 0;
    width: 100%;
    margin-bottom: $spacer;
  }

  .contact-form__input input,
  .contact-form__textarea textarea {
    font-size: $sm-fs * 1.15;
  }

  .contact-form__textarea textarea {
    font-size: $sm-fs * 1.05;
    padding: $spacer-small;
    line-height: $spacer;
  }

  .contact-form__submit {
    &:hover {
      opacity: 1;
    }
  }

  .contact-form__ch-count {
    text-align: left;
  }

  .textarea__subtext {
    display: flex;
    align-items: flex-start;
    flex-direction: column-reverse;

    .contact-form__error-msg {
      margin-top: $spacer-small;
    }
  }

  // Error Message & Success Message
  .contact-page__error-box,
  .contact-page__success-box {
    width: 80vw;
  }

  // Contact Page - Schedule a Visit
  .contact-sv {
    flex-direction: column;
    align-items: center;
    padding: $spacer * 1.25;
  }

  .contact-sv__title {
    font-size: $md-fs * 1.15;
    margin-bottom: $spacer-small * 0.75;
    text-align: center;
    line-height: $spacer * 1.5;
  }

  .contact-sv__text {
    font-size: $sm-fs * 1.15;
    max-width: 45ch;
    margin-right: 0;
    text-align: center;
    margin-bottom: $spacer * 1.5;
  }

  .contact-details {
    border-left: none;
    border-top: 2px solid #eee;
    padding-top: $spacer * 1.5;
    padding-left: 0;
  }

  .contact-details__title {
    text-align: center;
  }

  .contact-details__info {
    border-radius: 25px;
    padding: $spacer-small * 0.5 $spacer * 1.5;
    padding-right: $spacer * 1.5;
    text-align: center;

    svg {
      margin-right: $spacer-small * 0.5;
    }
  }
}
