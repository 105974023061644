// Media Queries
@media (max-width: 435px) {
  /* smartphones, iPhone, portrait 480x320 phones */
  .home-page {
    padding-top: 0;
  }

  // Rectangles
  .yellow-rect {
    display: none;
  }

  .green-rect {
    top: -30vh;
    right: -100vw;

    // Styles from FIGMA
    width: 600px;
    height: 600px;
  }

  // Hero Content
  .hero {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    padding: 0 $m-padding;
    margin-top: 20vh;
  }

  .hero-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100vw;
  }

  .hero-content__title {
    font-size: $lg-fs * 1.5;
    line-height: $spacer-md * 1.15;
    text-align: center;
  }

  .hero-content__text {
    max-width: 90vw;
    line-height: $spacer;
    margin-bottom: $spacer-md;
    text-align: center;
  }

  .hero__img {
    width: 70vw;
    height: 60vw;
    border-radius: 25px 5px;
    margin-bottom: $spacer-md;
  }

  // Inside Images
  .inside-images {
    margin-top: $spacer-lg * 1.25;
  }

  .bg-svg {
    display: none;
  }

  .inside-images-content {
    padding: $spacer-md 0;
    min-height: 70vh;
    border-radius: 0;
    margin-bottom: $spacer-lg;

    .item {
      width: 90vw;
      height: 30vh;
    }

    .carousel__img {
      width: 100%;
      height: 100%;
    }

    .carousel {
      padding-top: $spacer-md;
    }
  }

  .inside-images-content__title {
    max-width: 70vw;
    text-align: center;
    font-size: $lg-fs * 1.25;
    line-height: $spacer-md;
    margin: 0 auto;
  }

  // About Info
  .about-info {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: fit-content;
    height: 290vh;
    border-radius: 0;
    margin-bottom: $spacer-lg;
  }

  // Icons
  .about-info__star {
    display: none;
  }

  .about-desc__puzzle {
    display: none;
  }

  // Content
  .about-desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 85vh;
    background: $light-green;
    padding-top: $spacer-md * 1.5;
    padding-right: $m-padding;
    padding-left: $m-padding;
    border-radius: 0;
    margin-bottom: $spacer-md;
  }

  .about-desc__title {
    font-size: $lg-fs;
    text-align: center;
    margin-bottom: $spacer;
  }

  .about-desc__text {
    width: 90vw;
    text-align: center;
    line-height: $spacer * 1.25;
    margin-bottom: $spacer-md;
  }

  .about-desc__link {
    width: fit-content;
    padding: $spacer-small * 0.75 $spacer-md;
    font-size: $sm-fs * 1.15;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);

    &:hover {
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    }
  }

  .about-cards {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0;
    column-gap: 0;
    width: 100%;
    min-height: 100%;
    padding: $spacer $m-padding;
  }

  .about-cards__title,
  .about-cards__info {
    text-align: center;
  }

  .about-cards__card {
    width: 90vw;
    margin-bottom: $spacer-md * 1.5;
  }

  .about-cards__icon {
    $s: 5rem;
    top: $s * -0.25;
    left: 50%;
    transform: translateX(-50%);
  }

  #female-teacher {
    box-shadow: 0 0 7.5px 0px rgba(0, 0, 0, 0.25);

    .about-cards__icon {
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    }
  }

  #health,
  #shapes {
    transform: translateY(0);
  }

  #language img {
    transform: rotate(-21deg);
  }

  // Schedule Visit
  .schedule-visit {
    margin-top: $spacer-lg * 1.25;
    margin-bottom: $spacer-lg;

    .red-wave {
      display: none;
    }
  }

  .sv-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $red;
    padding: $spacer-md $m-padding;
    z-index: 100;
    min-height: 125vh;

    .between-row {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-items: flex-start;
    }
  }

  .sv-content__title {
    font-size: $lg-fs;
    line-height: $spacer * 1.75;
    margin-bottom: $spacer-md;
  }

  .video-box {
    position: relative;
    width: 90vw;
    height: 30vh;
    border-radius: 15px;
    margin-bottom: $spacer-md;

    video,
    .react-player__preview {
      transform: scale(1.05);
      border-radius: 15px;
    }
  }

  .contact-box {
    width: 100%;
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.15);
  }

  .contact-box__link {
    padding: $spacer-small * 0.5 $spacer-md;
  }

  // About the Provider ---- FROM OLD VERSION OF WEBSITE
  .about-provider {
    margin-bottom: $spacer-lg * 2;
  }

  .about-provider__underline {
    display: none;
  }

  .about-provider__title {
    max-width: 90vw;
    font-size: $lg-fs * 1.15;
    margin-bottom: $spacer-md;
    text-align: center;
  }

  .provider-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .provider-content__img {
    margin-right: 0;
    border-radius: 15px !important;
    margin-bottom: $spacer-md;
  }

  .provider-info {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__text {
      max-width: 85vw;
      margin-bottom: $spacer-md * 1.25;
      text-align: center;
    }

    &__link {
      background: $orange;
      color: white;

      &:hover {
        background: $orange;
        color: white;
      }
    }
  }
}
@media (max-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
}
@media (max-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}
@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}
